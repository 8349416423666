import { JetApp, plugins, EmptyRouter } from "webix-jet";
import { initJetWin } from "@xbs/jet-helpers";
import { createState, link } from "jet-restate";

import views from "./export_views";
import en from "locales/en";

import LocalData from "models/LocalData";
import Backend from "./models/Backend";

export class App extends JetApp {
	constructor(config) {
		const mode = config.mode || "tree";
		let structure = config.structure || {};

		const chart = config.chart || {};
		webix.extend(chart, { type: "bar", scale: "linear", lines: true });
		delete chart.id;

		const datatable = config.datatable || {};
		delete datatable.id;

		const state = createState({
			mode,
			structure,
			readonly: config.readonly || false,
			fields: config.fields || [],
			datatable,
			chart,
			config: false,
		});

		const defaults = {
			router: EmptyRouter,
			version: VERSION,
			debug: DEBUG,
			compactWidth: 720,
			start: "main/" + (mode == "chart" ? "chart" : "table"),
			params: { state, forceCompact: config.compact },
		};

		super({ ...defaults, ...config });

		// order matters!
		this.setService(
			"backend",
			new (this.dynamic(Backend))(this, this.config.url)
		);
		this.setService("local", new (this.dynamic(LocalData))(this, config));
		initJetWin(this);

		structure = this.prepareStructure(structure, true);

		this.use(
			plugins.Locale,
			this.config.locale || {
				lang: "en",
				webix: {
					en: "en-US",
				},
			}
		);
	}

	dynamic(obj) {
		return this.config.override ? this.config.override.get(obj) || obj : obj;
	}

	require(type, name) {
		if (type === "jet-views") return views[name];
		else if (type === "jet-locales") return locales[name];

		return null;
	}

	getState() {
		return this.config.params.state;
	}

	setStructure(structure) {
		this.getState().structure = this.prepareStructure(structure);
	}

	getStructure() {
		return this.getState().structure;
	}

	prepareStructure(structure, initial) {
		const mode = this.getState().mode;

		webix.extend(structure, {
			rows: [],
			columns: [],
			values: [],
			filters: [],
		});

		/* sync columns and groupBy depending on current mode*/
		if (initial) {
			if ((mode != "chart" || !structure.groupBy) && structure.columns.length)
				structure.groupBy = structure.columns[0];
			else if (structure.groupBy) structure.columns = [structure.groupBy];
		} else {
			if (mode != "chart") structure.groupBy = structure.columns[0];
			else {
				if (!structure.groupBy) structure.columns = [];
				else if (structure.columns[0] !== structure.groupBy)
					structure.columns = [structure.groupBy];
			}
		}

		const values = [];

		/* backward compatibility: 
			turn values like {name:"balance", operation:["min","max"]}
			into {name:"balance", operation:"min"}, {name:"balance", operation:"max"}
		*/
		for (let i = 0; i < structure.values.length; i++) {
			const value = structure.values[i];
			if (webix.isArray(value.operation)) {
				value.color =
					(webix.isArray(value.color) ? value.color : [value.color]) || [];
				for (let i = 0; i < value.operation.length; i++) {
					const obj = { ...value };
					obj.operation = value.operation[i];
					obj.color = value.color && value.color[i];

					values.push(obj);
				}
			} else values.push(value);
		}

		for (let i = 0; i < values.length; i++) {
			const local = this.getService("local");

			//assign colors for values if missing
			if (!values[i].color) values[i].color = local.getValueColor(i);

			//backward compatibility for complex math
			if (!values[i].name && !values[i].math) {
				values[i].math = values[i].operation;
				values[i].operation = "complex";
			}
			//operation with more than 2 fields
			else if (local.getOperation(values[i].operation).fields > 2) {
				const name = values[i].name;
				values[i].math = `${values[i].operation}(${
					webix.isArray(name) ? name.join() : name
				})`;
				values[i].operation = "complex";
			}
		}
		structure.values = values;

		return structure;
	}
}

webix.protoUI(
	{
		name: "pivot",
		app: App,
		defaults: {
			borderless: false,
		},
		$init: function() {
			this.name = "pivot";

			this.$view.className += " webix_pivot";

			const state = this.$app.getState();
			for (let key in state) {
				link(state, this.config, key);
			}

			this.$app.attachEvent("filter:change", (field, value) =>
				this.callEvent("onFilterChange", [field, value])
			);
		},
		$exportView: function(options) {
			const exportView = this.$app.getRoot().queryView({ $mainView: true });

			return exportView.$exportView
				? exportView.$exportView(options)
				: exportView;
		},
		getState() {
			return this.$app.getState();
		},
		getService(name) {
			return this.$app.getService(name);
		},
		setStructure: function(structure) {
			this.$app.setStructure(structure);
		},
		getStructure: function() {
			return this.$app.getStructure();
		},
		clearAll: function() {
			this.$app.getService("local").clearAll();
		},
	},
	webix.ui.jetapp
);

// re-export for customization
const services = { Backend, LocalData };
const locales = { en };
export { views, services, locales };
