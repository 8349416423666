export default {
	//general
	Done: "Done",
	Table: "Table",
	Tree: "Tree",
	Chart: "Chart",
	"Click to configure": "Click to configure",
	"Configure Pivot": "Configure Pivot",
	Total: "Total",
	//settings
	Fields: "Fields",
	Methods: "Methods",
	Columns: "Columns",
	"Add column": "Add column",
	Rows: "Rows",
	"Add row": "Add row",
	"Clean rows": "Clean rows",
	Filters: "Filters",
	"Add filter": "Add filter",
	"Group By": "Group By",
	"Chart type": "Chart type",
	"Logarithmic scale": "Logarithmic scale",
	"X axis title": "X axis title",
	"Y axis title": "Y axis title",
	"Scale color": "Scale color",
	"Circled lines": "Circled lines",
	Horizontal: "Horizontal",
	Stacked: "Stacked",
	Lines: "Lines",
	Line: "Line",
	Radar: "Radar",
	Bar: "Bar",
	Area: "Area",
	Spline: "Spline",
	"Spline Area": "Spline Area",
	Pie: "Pie",
	Donut: "Donut",
	Values: "Values",
	"Add value": "Add value",
	"Field not defined": "Field not defined",
	Highlight: "Highlight",
	"Min X": "Min X",
	"Max X": "Max X",
	"Min Y": "Min Y",
	"Max Y": "Max Y",
	Footer: "Footer",
	"Total Column": "Total Column",
	Off: "Off",
	On: "On",
	"Sum Only": "Sum Only",
	"3D": "3D",
	"Show values inside": "Show values inside",
	//operations
	count: "count",
	max: "max",
	min: "min",
	avg: "avg",
	wavg: "wavg",
	any: "any",
	sum: "sum",
	complex: "complex",
	"Incorrect formula in values": "Incorrect formula in values",
};
