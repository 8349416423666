import { JetView } from "webix-jet";

export default class ChartView extends JetView {
	config() {
		const state = (this.State = this.getParam("state", true));

		const chart = {
			view: "chart",
			$mainView: true,
			borderless: true,
			localId: "data",
			xAxis: {},
			yAxis: {},
		};

		webix.extend(chart, webix.copy(state.chart), true);

		const type = chart.type;
		if (type == "barH" || type == "stackedBarH") {
			if (!chart.yAxis.template)
				chart.yAxis.template = obj => obj[obj.length - 1];
		} else if (!chart.xAxis.template)
			chart.xAxis.template = obj => obj[obj.length - 1];

		const commonAxisSettings = {
			lines: chart.lines,
		};

		//save default chart line color
		if (chart.scaleColor)
			commonAxisSettings.color = commonAxisSettings.lineColor =
				chart.scaleColor;

		webix.extend(chart.xAxis, commonAxisSettings, true);
		webix.extend(chart.yAxis, commonAxisSettings, true);

		if (this.IsPieBased()) {
			webix.extend(
				chart,
				{
					multilevel: true,
					tooltip: this.ValueFormat,
					color: "#color#",
					type: type + (chart["3D"] ? "3D" : ""),
					pieInnerText: chart["pieInnerText"] ? this.ValueFormat : "",
				},
				true
			);
		}

		return chart;
	}

	init() {
		this.Local = this.app.getService("local");
		this.LoadData();

		this.on(this.State.$changes, "structure", (structure, old) => {
			if (old) this.LoadData();
		});

		this.on(this.State.$changes, "chart", (val, o) => {
			if (o) this.refresh();
		});
	}
	/**
	 * Loads data to a chart
	 * @returns {Promise} promise that resolves when data is parsed
	 */
	LoadData() {
		return this.Local.getData().then(data => {
			this.UpdateChart(data);
		});
	}
	/**
	 * Updates chart data
	 * @param {Array} data - array of data
	 */
	UpdateChart(data) {
		this.$$("data").clearAll();

		if (!this.IsPieBased()) {
			this.$$("data").removeAllSeries();
			this.SetSeries(data.values);
		}

		this.SetLegend(data.values);
		this.$$("data").parse(data.data);
	}
	/**
	 * Checks if chart is pie based
	 * @param {string} type (optional) - chart type
	 * @returns {boolean} is pie based
	 */
	IsPieBased(type) {
		if (!type) type = this.State.chart.type;
		return type == "pie" || type == "donut";
	}
	/**
	 * Sets chart series
	 * @param {Array} values - array of values for series
	 */
	SetSeries(values) {
		for (let i = 0; i < values.length; i++)
			this.$$("data").addSeries(this.GetSeriesConfig(values[i], i));
	}
	/**
	 * Prepares chart series
	 * @param {object} settings - values for series
	 * @param {number} i - index
	 * @param {string} type - chart type (customizations)
	 */
	GetSeriesConfig(settings, i, type) {
		type = type || this.State.chart.type;
		return {
			type,
			value: obj => obj[i],
			alpha: type == "area" || type == "splineArea" ? 0.7 : 1,
			color: settings.color,
			tooltip: obj => this.ValueFormat({ value: obj[i] }),
			item: {
				color: settings.color,
				borderColor: settings.color,
			},
			line: {
				color: settings.color,
			},
		};
	}
	/**
	 * Prepares and sets chart legend
	 * @param {Array} values - array of values for legend
	 */
	SetLegend(values) {
		const _ = this.app.getService("locale")._;

		values = values.map(v => {
			const operation = v.operation;
			let text = v.text;
			if (operation == "complex") text = this.Local.fixMath(text);
			else if (operation) {
				text = text
					.split(",")
					.map(t => this.Local.getField(t).value)
					.join(", ");
				text = `${text} (${_(operation)})`;
			}
			return { color: v.color, text };
		});

		const legend = webix.extend(
			{
				values,
				valign: "middle",
				align: "right",
				layout: "y",
			},
			this.State.chart.legend || {},
			true
		);
		this.$$("data").define({ legend });
	}
	/**
	 * Formats tooltip values / inner text
	 * @param {object} item - data item
	 * @returns {number} formatted value
	 */
	ValueFormat(item) {
		return item.text || parseFloat(item.value || 0).toFixed(3);
	}
}
