import "helpers/complex";
import Property from "views/config/properties";

export default class ValuesProperty extends Property {
	constructor(app, name, config) {
		super(app, name, config);
		this.Local = this.app.getService("local");

		this._ = this.app.getService("locale")._;

		this.typeName = "operation";
		this.plusLabel = this._("Add value");
		this.field = "values";

		this.operations = this.Local.operations;

		this.operations.map(operation => {
			operation.value = this._(operation.id);
			return operation;
		});
	}
	init() {
		super.init();

		this.State = this.app.getState();

		this.on(this.State.$changes, "mode", () => {
			this.ToggleColors();
		});
	}
	GetValue() {
		const forms = this.$$("forms").getChildViews();
		const arr = [];

		forms.forEach(form => {
			const values = form.getChildViews()[0].getValues({ hidden: false });
			if (!webix.isUndefined(values.name2)) {
				//wavg
				if (values.name && values.name2) {
					values.name = [values.name, values.name2];
					delete values.name2;
				} else values.name = "";
			}

			if (values.name == "" || values.math == "") return;
			if (values.math) values.math = this.OutComplexMath(values.math);
			arr.push(values);
		});

		return arr;
	}
	ItemConfig(val, i) {
		const config = super.ItemConfig(val);

		let operation;
		if (val) operation = val.operation;
		else {
			const def = this.app.config.defaultOperation;
			const defIndex = Math.max(this.operations.findIndex(a => a.id == def), 0);
			operation = this.operations[defIndex].id;
		}

		config.splice(2, 0, {
			view: "richselect",
			name: "operation",
			width: 110,
			value: operation,
			options: {
				css: "webix_pivot_suggest",
				data: this.operations.filter(
					obj => obj.fields <= 2 || obj.id == "complex"
				),
			},
			on: {
				onChange: function(v) {
					this.$scope.SetOperation(v, this);
				},
			},
		});

		config.splice(2, 0, {
			view: "richselect",
			hidden: this.Local.getOperation(operation).fields != 2,
			value: val && webix.isArray(val.name) ? val.name[1] : "",
			name: "name2",
			options: {
				css: "webix_pivot_suggest",
				data: this.app.getState().fields,
			},
		});

		//custom math
		config.splice(1, 0, {
			view: "math-editor",
			name: "math",
			hidden: !val || val.operation != "complex",
			value: val && val.math ? this.Local.fixMath(val.math) : "",
			suggest: {
				view: "suggest-math",
				data: this.SuggestData(),
			},
		});

		const mini = webix.skin.$name == "mini" || webix.skin.$name == "compact";
		const palette = this.Local.getPalette();
		const color = (val && val.color) || this.Local.getValueColor(i);

		config.splice(1, 0, {
			view: "colorpicker",
			hidden: this.State.mode != "chart",
			name: "color",
			css: "webix_pivot_value_color",
			value: color,
			width: mini ? 30 : 38,
			suggest: {
				type: "colorboard",
				body: {
					width: 150,
					height: 150,
					view: "colorboard",
					palette,
				},
			},
		});

		return config;
	}
	ToggleColors() {
		const mode = this.State.mode;

		const layout = this.$$("forms");
		const forms = layout.getChildViews();

		for (let i = 0; i < forms.length; i++) {
			const input = forms[i].getChildViews()[0].elements.color;
			if (mode == "chart") input.show();
			else input.hide();
		}
	}
	FilterSuggest() {
		return true;
	}
	SetOperation(val, view) {
		const form = view.getFormView();
		const elements = form.elements;

		if (val == "complex") {
			elements.name.hide();
			elements.name2.hide();
			elements.math.show();

			elements.name.setValue();
			elements.name2.setValue();
		} else if (this.Local.getOperation(val).fields == 2) {
			elements.name.show();
			elements.name2.show();
			elements.math.hide();
		} else {
			elements.name.show();
			elements.name2.hide();
			elements.math.hide();
		}
	}
	SuggestData() {
		return [
			{
				value: this._("Fields"),
				id: "$fields",
				disabled: true,
				$css: "webix_pivot_values_section",
			},
			...this.app.getState().fields,
			{
				value: this._("Methods"),
				id: "$methods",
				disabled: true,
				$css: "webix_pivot_values_section",
			},
			...this.operations.filter(obj => obj.id != "complex"),
		];
	}
	OutComplexMath(math) {
		const fields = this.app.getState().fields;
		const fieldsRegex = new RegExp(
			"(\\(|\\s|,|^)(" +
				fields
					.map(field => field.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"))
					.join("|") +
				")(\\)|\\s|,|$)",
			"g"
		);

		const fieldsMatch = Array.from(math.matchAll(fieldsRegex));
		for (let i = fieldsMatch.length - 1; i >= 0; i--) {
			const match = fieldsMatch[i];
			math =
				math.substring(0, match.index + match[1].length) +
				fields.find(obj => obj.value == match[2]).id +
				math.substring(
					match.index + match[0].length - match[3].length,
					math.length
				);
		}

		const methods = this.operations;
		const methodsRegex = new RegExp(
			"(\\(|,|\\+|-|\\/|\\*|\\s|^)(" +
				methods
					.map(method =>
						method.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
					)
					.join("|") +
				")\\(",
			"g"
		);

		const methodsMatch = Array.from(math.matchAll(methodsRegex));
		for (let i = methodsMatch.length - 1; i >= 0; i--) {
			const match = methodsMatch[i];
			math =
				math.substring(0, match.index + match[1].length) +
				this.operations.find(operation => operation.value == match[2]).id +
				math.substring(match.index + match[0].length - 1, math.length);
		}

		return math;
	}
}
