import { JetView } from "webix-jet";

export default class ChartSettings extends JetView {
	config() {
		const _ = this.app.getService("locale")._;
		return {
			view: "form",
			complexData: true,
			padding: 10,
			elementsConfig: {
				labelWidth: 120,
			},
			elements: [
				{
					name: "type",
					view: "richselect",
					value: "bar",
					options: {
						css: "webix_pivot_suggest",
						data: [
							{ id: "bar", value: _("Bar") },
							{ id: "line", value: _("Line") },
							{ id: "radar", value: _("Radar") },
							{ id: "area", value: _("Area") },
							{ id: "spline", value: _("Spline") },
							{ id: "splineArea", value: _("Spline Area") },
							{ id: "pie", value: _("Pie") },
							{ id: "donut", value: _("Donut") },
						],
					},
				},
				{
					name: "xAxis.title",
					view: "text",
					label: _("X axis title"),
					batch: "axis",
				},
				{
					name: "yAxis.title",
					view: "text",
					label: _("Y axis title"),
					batch: "axis",
				},
				{
					name: "scaleColor",
					batch: "scale",
					view: "colorpicker",
					editable: true,
					label: _("Scale color"),
					suggest: {
						type: "colorboard",
						body: {
							width: 150,
							height: 150,
							view: "colorboard",
							palette: this.app.getService("local").getPalette(),
						},
					},
				},
				{
					name: "3D",
					view: "checkbox",
					batch: "pie",
					labelWidth: 0,
					labelRight: _("3D"),
				},
				{
					name: "pieInnerText",
					view: "checkbox",
					batch: "pie",
					labelWidth: 0,
					labelRight: _("Show values inside"),
				},
				{
					name: "stacked",
					view: "checkbox",
					batch: "stacked",
					labelWidth: 0,
					labelRight: _("Stacked"),
				},
				{
					name: "horizontal",
					view: "checkbox",
					batch: "bar",
					labelWidth: 0,
					labelRight: _("Horizontal"),
				},
				{
					name: "scale",
					batch: "scale",
					view: "checkbox",
					checkValue: "logarithmic",
					uncheckValue: "linear",
					labelWidth: 0,
					labelRight: _("Logarithmic scale"),
				},
				{
					name: "yAxis.lineShape",
					view: "checkbox",
					batch: "radar",
					checkValue: "arc",
					labelWidth: 0,
					labelRight: _("Circled lines"),
				},
				{
					name: "lines",
					batch: "scale",
					view: "checkbox",
					labelWidth: 0,
					labelRight: _("Lines"),
				},
			],
			on: {
				onChange: (value, oldValue, source) => {
					if (source == "user") {
						const out = this.OutValues(this.getRoot().getValues());

						this.innerChange = true;
						this.State.chart = Object.assign({}, out);
						this.HandleVisibility();
						delete this.innerChange;
					}
				},
			},
		};
	}
	init() {
		this.State = this.getParam("state", true);

		this.on(this.State.$changes, "chart", chart => {
			if (!this.innerChange) {
				this.getRoot().setValues(this.InValues(chart));
				this.HandleVisibility();
			}
		});
	}
	HandleVisibility() {
		const form = this.getRoot();
		const type = form.getValues().type;
		const pieBased = type == "pie" || type == "donut";

		if (pieBased) form.showBatch("pie");
		else {
			form.showBatch("scale");
			if (type == "radar") form.showBatch("radar", true);
			else {
				form.showBatch("axis", true);
				const bar = type == "bar";
				if (bar || type == "area") {
					if (bar) form.showBatch("bar", true);
					form.showBatch("stacked", true);
				}
			}
		}
	}
	InValues(values) {
		values = webix.copy(values);
		const type = values.type.toLowerCase();
		const stackedArea = type == "stackedarea";

		if (type.indexOf("bar") != -1 || stackedArea) {
			if (type.indexOf("stacked") != -1) values.stacked = 1;
			if (type.indexOf("h") != -1) values.horizontal = 1;
			values.type = stackedArea ? "area" : "bar";
		}

		return values;
	}
	OutValues(values) {
		const bar = values.type == "bar";
		if (bar || values.type == "area") {
			if (bar && values.horizontal) {
				values.type += "H";
				delete values.horizontal;
			}
			if (values.stacked) {
				values.type =
					"stacked" + values.type[0].toUpperCase() + values.type.slice(1);
				delete values.stacked;
			}
		}

		return values;
	}
}
