import { JetView } from "webix-jet";

export default class TableSettings extends JetView {
	config() {
		const _ = this.app.getService("locale")._;

		return {
			view: "form",
			padding: 10,
			elements: [
				{
					name: "cleanRows",
					localId: "cleanRows",
					view: "checkbox",
					labelWidth: 0,
					labelRight: _("Clean rows"),
				},
				{
					view: "label",
					height: 20,
					label: _("Highlight"),
				},
				{
					cols: [
						{
							name: "minX",
							view: "checkbox",
							labelWidth: 0,
							labelRight: _("Min X"),
						},
						{
							name: "maxX",
							view: "checkbox",
							labelWidth: 0,
							labelRight: _("Max X"),
						},
						{
							name: "minY",
							view: "checkbox",
							labelWidth: 0,
							labelRight: _("Min Y"),
						},
						{
							name: "maxY",
							view: "checkbox",
							labelWidth: 0,
							labelRight: _("Max Y"),
						},
					],
				},
				{
					view: "label",
					height: 20,
					label: _("Footer"),
				},
				{
					view: "radio",
					name: "footer",
					options: [
						{ id: 1, value: _("Off") },
						{ id: 2, value: _("On") },
						{ id: 3, value: _("Sum Only") },
					],
					value: 1,
				},
				{
					view: "label",
					height: 20,
					label: _("Total Column"),
				},
				{
					view: "radio",
					name: "totalColumn",
					options: [
						{ id: 1, value: _("Off") },
						{ id: 2, value: _("On") },
						{ id: 3, value: _("Sum Only") },
					],
					value: 1,
				},
			],
			on: {
				onChange: (value, oldValue, source) => {
					if (source == "user") {
						const out = this.OutValues(this.getRoot().getValues());

						this.innerChange = true;
						this.State.datatable = Object.assign({}, out);
						delete this.innerChange;
					}
				},
			},
		};
	}
	init() {
		this.State = this.getParam("state", true);

		this.on(this.State.$changes, "datatable", datatable => {
			if (!this.innerChange) this.getRoot().setValues(this.InValues(datatable));
		});

		this.on(this.State.$changes, "mode", mode => {
			if (mode == "table") this.$$("cleanRows").show();
			else this.$$("cleanRows").hide();
		});
	}
	InValues(values) {
		values = webix.copy(values);

		if (values.footer) values.footer = values.footer == "sumOnly" ? 3 : 2;
		else values.footer = 1;

		if (values.totalColumn)
			values.totalColumn = values.totalColumn == "sumOnly" ? 3 : 2;
		else values.totalColumn = 1;

		return values;
	}
	OutValues(values) {
		switch (values.footer) {
			case "1":
				delete values.footer;
				break;
			case "2":
				values.footer = true;
				break;
			case "3":
				values.footer = "sumOnly";
				break;
		}

		switch (values.totalColumn) {
			case "1":
				delete values.totalColumn;
				break;
			case "2":
				values.totalColumn = true;
				break;
			case "3":
				values.totalColumn = "sumOnly";
				break;
		}

		return values;
	}
}
