import { JetView } from "webix-jet";

export default class TableView extends JetView {
	config() {
		this.Local = this.app.getService("local");
		const state = (this.State = this.getParam("state", true));
		const rows = state.structure.rows.length;

		const table = {
			view: "treetable",
			$mainView: true,
			localId: "data",
			css: "webix_data_border webix_header_border",
			select: true,
			leftSplit: state.mode == "table" ? rows : rows ? 1 : 0,
			resizeColumn: true,
			borderless: true,
			columns: [],
			footer: state.datatable.footer,
		};

		webix.extend(table, state.datatable, true);

		return table;
	}

	init() {
		this.LoadData();

		this.on(this.State.$changes, "structure", (structure, old) => {
			if (old) this.LoadData();
		});

		this.on(this.State.$changes, "datatable", (val, o) => {
			if (o) this.refresh();
		});

		this.on(this.State.$changes, "mode", (val, o) => {
			if (o && o != "chart" && val != "chart") this.refresh();
		});
	}
	/**
	 * Loads data to a table
	 * @returns {Promise} promise that resolves when data is parsed
	 */
	LoadData() {
		return this.Local.getData().then(data => {
			this.UpdateTable(data);
		});
	}
	/**
	 * Updates table data
	 * @param {Array} data - array of data
	 */
	UpdateTable(data) {
		const state = this.State;
		const structure = state.structure;

		const freeze = this.CheckFreeze();

		let rightSplit = 0;
		const totalCols = state.datatable.totalColumn;
		let totalOps;
		if (totalCols) {
			const vals = structure.values;
			totalOps =
				totalCols == "sumOnly" ? vals.filter(v => v.operation == "sum") : vals;
			if (freeze) rightSplit = totalOps.length;
		}

		let leftSplit = 0;
		if (freeze) {
			const rows = structure.rows.length;
			leftSplit = state.mode == "table" ? rows : rows ? 1 : 0;
		}

		if (!data.$ready) {
			const total = data.totalColumn;
			let count;

			if (total) {
				count = data.header.length;

				for (let i = 0; i < totalOps.length; i++) {
					let name = totalOps[i].name;
					if (webix.isArray(name)) name = name.join();

					data.header.push({
						header: [
							{ name: "total", colspan: rightSplit },
							{
								text: name || totalOps[i].operation,
								operation: totalOps[i].operation,
							},
						],
						id: data.header.length + 1,
					});
				}
			}

			const totalIndex = { i: 0 };
			data = this.Local._store = {
				data: data.data.map(item =>
					this._prepareData(item, total, totalIndex, count)
				),
				columns: this.SetColumns(data.header, data.footer, data.marks),
				$ready: 1,
			};
		}

		const table = this.$$("data");
		table.clearAll();
		table.define({ leftSplit, rightSplit }, true);
		table.refreshColumns(data.columns);
		table.parse(data.data);
	}
	/**
	 * Prepares table item (updates id, adds total cols, etc.)
	 * @param {Array} obj - data item
	 * @param {Array} total - array with total values for columns
	 * @param {Object} index - current total index
	 * @param {number} count - the amount of data in the item
	 * @returns {Proxy} prepared item
	 */
	_prepareData(obj, total, index, count) {
		let totalIndex;

		if (total && !obj.data) totalIndex = index.i++;

		const item = new Proxy(obj, {
			get: (item, prop) => {
				if (prop == "data") return item.vals;

				const id = prop * 1;
				if (!isNaN(id)) {
					const vals = typeof item.values == "object" ? item.values : item;

					if (id > count && (totalIndex || totalIndex === 0) && total) {
						return total[totalIndex][id - count - 1];
					}

					return vals[id - 1];
				}

				return item[prop];
			},
		});

		if (obj.data) {
			item.open = true;
			item.vals = obj.data.map(r => {
				return this._prepareData(r, total, index, count);
			});
		}

		return item;
	}
	/**
	 * Checks if columns should be frozen
	 */
	CheckFreeze() {
		const freezeColumns = this.app.config.freezeColumns;
		const compact = this.getParam("compact", true);

		return webix.isUndefined(freezeColumns) ? !compact : freezeColumns;
	}
	/**
	 * Prepares table columns
	 * @param {Array} columns - array with columns
	 * @param {Array} footer - array with total values for columns
	 * @param {Array} marks - array with min/max marks for columns
	 * @returns {Array} column configurations
	 */
	SetColumns(columns, footer, marks) {
		columns = webix.copy(columns);
		const _ = this.app.getService("locale")._;

		const rows = this.State.structure.rows.length;
		const left = this.State.mode == "table" ? rows : rows ? 1 : 0;

		for (let i = 0; i < columns.length; i++) {
			if (i < left) {
				this.SetFirstColumn(columns[i], !i && this.State.datatable.footer, _);
			} else {
				columns[i].sort = "int";

				if (!columns[i].format) columns[i].format = this.CellFormat;

				if (marks.length)
					columns[i].cssFormat = (v, row, rid, cid) => {
						const col = marks[rid - 1];
						const css = col ? col[cid - 1] : null;
						return css ? css.join(" ") : "";
					};

				const header = columns[i].header;
				for (let j = 0; j < header.length; j++) {
					let h = header[j];
					if (h) {
						if (!j && h.name == "total") {
							h.text = _("Total");
						} else if (j == header.length - 1) {
							h.text = this.HeaderTemplate(h, _);
						}
					}
				}
				if (footer.length) {
					columns[i].footer = this.CellFormat(footer[i]);
				}
			}
		}

		return columns;
	}
	/**
	 * Prepares first column
	 * @param {Object} column - column configuration
	 * @param {boolean} footer - column with footer name
	 * @param {function} _ - function for translating text labels
	 */
	SetFirstColumn(column, footer, _) {
		if (this.State.mode == "tree") {
			column.header = {
				text: this.State.structure.rows
					.map(field => this.Local.getField(field).value)
					.join("<span class='webix_icon wxi-angle-right'></span>"),
				css: "webix_pivot_tree_header",
			};
			column.width = 300;
			column.template = (obj, common) => {
				return common.treetable(obj, common) + obj[1];
			};
		} else {
			column.header = this.Local.getField(column.header[0].text).value;
			column.width = 200;
		}
		if (footer) column.footer = _("Total");
	}
	/**
	 * Sets a template for column header
	 * @param {Object} line - object with header line properties
	 * @param {function} _ - function for translating text labels
	 * @returns {string} string with header text
	 */
	HeaderTemplate(line, _) {
		if (!line.operation || line.operation == "complex")
			return this.Local.fixMath(line.text);
		else {
			let text = line.text.split(",");
			text = text.map(t => this.Local.getField(t).value).join(", ");
			return `${text} <span class="webix_pivot_operation">${_(
				line.operation
			)}</span>`;
		}
	}
	/**
	 * Formats a cell value
	 * @param {(number|string)} value - raw value
	 * @returns {(number|string)} formatted value
	 */
	CellFormat(value) {
		if (!value) value = value === 0 ? "0" : "";

		return value ? parseFloat(value).toFixed(3) : value;
	}
}
